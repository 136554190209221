import React, { useState } from 'react'
import { BlockContent } from './blockContent'
import { graphql, useStaticQuery } from 'gatsby'
import { ContactQuery } from '../../graphql-types'

interface IFormState {
    name: string
    email: string
    message: string
}

// Netlify form handling from https://www.netlify.com/blog/2017/07/20/how-to-integrate-netlifys-form-handling-in-a-react-app/
export function Contact() {
    const data: ContactQuery = useStaticQuery(graphql`
        query Contact {
            sanityContact {
                header
                phone
                email
                _rawHours(resolveReferences: { maxDepth: 10 })
                _rawLocation(resolveReferences: { maxDepth: 10 })
                messagePrompt
                namePrompt
                submitCompleteText
                submitText
                formPrompt
                emailPrompt
            }
        }
    `)
    // Delete all brackets and whitespace
    const parsedPhone = data.sanityContact?.phone?.replace(/[()\s-]/g, '')
    const formName = 'Contact'

    const [formSubmitted, setFormSubmitted] = useState(false)
    const [formError, setFormError] = useState<string | null>(null)
    const [formState, setFormState] = useState<IFormState>({
        name: '',
        email: '',
        message: '',
    })

    function handleChange(e) {
        e.preventDefault()
        e.persist()
        setFormState(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }))
    }

    function encode(data) {
        return Object.keys(data)
            .map(
                key =>
                    encodeURIComponent(key) +
                    '=' +
                    encodeURIComponent(data[key]),
            )
            .join('&')
    }

    function handleSubmit(e) {
        fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({ 'form-name': formName, ...formState }),
        })
            .then(() => {
                setFormError(null)
                setFormSubmitted(true)
                alert('Your message has been sent!')
            })
            .catch((error: object) => {
                setFormError(error.toString())
                setFormSubmitted(false)
                alert(error.toString())
            })

        e.preventDefault()
    }

    const contactLinkClasses = 'underline tracking-wider text-lg'

    const namePrompt = data.sanityContact?.namePrompt || 'Enter your name'
    const emailPrompt = data.sanityContact?.emailPrompt || 'Enter your email'
    const messagePrompt =
        data.sanityContact?.messagePrompt || 'Enter your message'
    return (
        <section
            className="col-center bg-primary -mt-10 section pt-20"
            id="contact"
        >
            <h2>{data.sanityContact?.header}</h2>
            <div className="flex md:m-8 flex-wrap justify-around w-full max-w-3xl">
                <div className="col-center py-6 space-y-5 m-auto">
                    <BlockContent blocks={data.sanityContact?._rawLocation} />
                    <a
                        href={'mailto:' + data.sanityContact?.email}
                        className={contactLinkClasses}
                    >
                        {data.sanityContact?.email}
                    </a>
                    <a
                        href={'tel:1' + parsedPhone}
                        className={contactLinkClasses}
                    >
                        {data.sanityContact?.phone}
                    </a>
                    <BlockContent blocks={data.sanityContact?._rawHours} />
                </div>
                <form
                    name={formName}
                    method="post"
                    data-netlify="true"
                    onSubmit={handleSubmit}
                    className="py-6 px-2 border-t-2 border-b-2 border-primary m-auto w-64"
                    aria-disabled={formSubmitted}
                >
                    <input type="hidden" name="form-name" value={formName} />
                    <fieldset className="space-y-4 flex flex-col justify-start">
                        <legend className="text-header text-2xl font-bold tracking-wider">
                            {data.sanityContact?.formPrompt ||
                                'Request a Quote/Estimate:'}
                        </legend>

                        <label htmlFor="name" hidden>
                            {namePrompt}
                        </label>
                        <input
                            type="text"
                            name="name"
                            id="name"
                            value={formState.name}
                            onChange={handleChange}
                            placeholder={namePrompt}
                            className="w-full"
                        />

                        <label htmlFor="email" hidden>
                            {emailPrompt}
                        </label>
                        <input
                            type="email"
                            name="email"
                            id="email"
                            value={formState.email}
                            onChange={handleChange}
                            placeholder={emailPrompt}
                            className="w-full"
                        />

                        <label htmlFor="message" hidden>
                            {messagePrompt}
                        </label>
                        <textarea
                            name="message"
                            id="message"
                            value={formState.message}
                            onChange={handleChange}
                            placeholder={messagePrompt}
                            className="h-40 w-full"
                        />

                        <button
                            type="submit"
                            className={`uppercase rounded border border-primary text-header py-2 px-8 mr-auto transform duration-200 hover:shadow-2xl focus:shadow-2xl ${
                                formSubmitted ? 'opacity-50 cursor-auto' : ''
                            }`}
                            disabled={formSubmitted}
                        >
                            {formSubmitted
                                ? data.sanityContact?.submitCompleteText ||
                                  'Submitted!'
                                : data.sanityContact?.submitText || 'Submit'}
                        </button>
                        {formError && <div>An error occurred: {formError}</div>}
                    </fieldset>
                </form>
            </div>
        </section>
    )
}
