import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { graphql, useStaticQuery } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'

import { OurWorkQuery } from '../../graphql-types'
import { isBrowser } from '../utils/vars'
import XSVG from '../images/x.inline.svg'
import LeftSVG from '../images/chevron-left.inline.svg'
import RightSVG from '../images/chevron-right.inline.svg'

interface ImageData {
    key: string
    fluid: FluidObject
    alt: string
    title: string
}

export function OurWork() {
    const data: OurWorkQuery = useStaticQuery(graphql`
        query OurWork {
            sanityOurWork {
                header
                filterPrompt
                numInitialImages
                showAllImagesText
                showFewerImagesText
                work {
                    name
                    image {
                        myImage {
                            asset {
                                fluid(maxWidth: 1000) {
                                    ...GatsbySanityImageFluid
                                }
                            }
                        }
                        alt
                    }
                    workCategories {
                        name
                    }
                }
            }
            allSanityWorkCategory {
                nodes {
                    name
                }
            }
        }
    `)

    const allCategoryName = 'All'
    const [category, setCategory] = useState(allCategoryName)
    const [popupActive, setPopupActive] = useState(false)
    const [popupImageIndex, setPopupImageIndex] = useState(0)
    const [showingAllImages, setShowingAllImages] = useState(false)
    const numInitialImages = data.sanityOurWork?.numInitialImages || 5
    const [numImages, setNumImages] = useState(numInitialImages)

    const filteredWork =
        category === allCategoryName
            ? data.sanityOurWork?.work
            : data.sanityOurWork?.work?.filter(work =>
                  work?.workCategories
                      ?.map(category => category?.name)
                      .includes(category),
              )

    const imagesData: ImageData[] | undefined = filteredWork?.map(work => {
        return {
            key:
                'image' +
                work?.image?.alt +
                work?.image?.myImage?.asset?.fluid?.src,
            fluid: work?.image?.myImage?.asset?.fluid as FluidObject,
            alt: work?.image?.alt || 'Work item',
            title: work?.name || 'Work item',
        }
    })

    return (
        <section
            className="col-center space-y-6 section m-auto w-screen"
            id="ourWork"
        >
            <h2>{data.sanityOurWork?.header}</h2>
            <div>
                <label htmlFor="workCategory" className="mx-2">
                    {data.sanityOurWork?.filterPrompt || 'Filter by category:'}
                </label>
                <select
                    name="workCategory"
                    id="workCategory"
                    value={category}
                    onChange={e => setCategory(e.target.value)}
                    className="bg-primary border-b border-primary p-2 shadow rounded"
                >
                    <option value={allCategoryName} id={allCategoryName}>
                        {allCategoryName}
                    </option>
                    {data.allSanityWorkCategory?.nodes.map(({ name }, i) => {
                        if (name)
                            return (
                                <option key={name + i} value={name} id={name}>
                                    {name}
                                </option>
                            )
                        else return null
                    })}
                </select>
            </div>
            <ul className="flex flex-wrap items-center justify-around content-around p-5 max-w-full container">
                {imagesData?.slice(0, numImages).map((imageData, i) => {
                    if (imageData.fluid) {
                        return (
                            <li className="py-3 px-2" key={imageData.key || i}>
                                <button
                                    onClick={() => {
                                        setPopupActive(true)
                                        setPopupImageIndex(i)
                                    }}
                                >
                                    <Img
                                        {...imageData}
                                        className="rounded-lg w-64 m-auto transform hover:scale-101 duration-100"
                                        title={imageData.title}
                                    />
                                </button>
                            </li>
                        )
                    }
                })}
            </ul>
            <button
                className={`bg-primary uppercase rounded border border-primary text-header py-2 px-8 transform duration-200 hover:shadow-2xl focus:shadow-2xl`}
                onClick={() => {
                    if (imagesData) {
                        if (showingAllImages) setNumImages(numInitialImages)
                        else setNumImages(imagesData.length)
                        setShowingAllImages(prev => !prev)
                    }
                }}
            >
                {showingAllImages
                    ? data.sanityOurWork?.showFewerImagesText ||
                      'Show fewer images'
                    : data.sanityOurWork?.showAllImagesText ||
                      'Show all images'}
            </button>
            {isBrowser &&
                popupActive &&
                imagesData &&
                ReactDOM.createPortal(
                    <ImagePopup
                        imagesData={imagesData}
                        initialIndex={popupImageIndex}
                        setPopupActive={setPopupActive}
                    />,
                    document.body,
                )}
        </section>
    )
}

function ImagePopup({
    setPopupActive,
    imagesData,
    initialIndex,
}: {
    setPopupActive: (value: React.SetStateAction<boolean>) => void
    imagesData: ImageData[]
    initialIndex: number
}) {
    const [imageIndex, setImageIndex] = useState(initialIndex)

    useEffect(() => {
        function keyListener(event: KeyboardEvent) {
            if (event.code === 'Escape') {
                setPopupActive(false)
            }
        }
        if (isBrowser) {
            window.addEventListener('keydown', keyListener)
            return () => {
                window.removeEventListener('keydown', keyListener)
            }
        }
    }, [])

    function handleArrowClick(direction: 'left' | 'right') {
        let nextImage = direction === 'left' ? imageIndex - 1 : imageIndex + 1
        if (nextImage < 0) {
            nextImage = imagesData.length - 1
        } else if (nextImage >= imagesData.length) {
            nextImage = 0
        }
        setImageIndex(nextImage)
    }

    const imgStyle = {
        height: '100%',
        width: 'auto',
        margin: 'auto',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    }

    const arrowButtonClasses = `fixed top-auto bottom-auto z-50 text-logo transform hover:scale-110 duration-150`
    const arrowSVGClasses = `w-20 h-auto fill-primary stroke-current`

    return (
        <div
            className={
                'fixed top-0 left-0 w-full h-full z-50 bg-black bg-opacity-90 flex items-center justify-center text-header'
            }
        >
            <button
                className={`fixed top-0 right-0 m-4 z-50`}
                onClick={_ => setPopupActive(false)}
            >
                <XSVG
                    className={`w-16 h-auto transform hover:scale-105 duration-100`}
                />
            </button>
            <button
                className={`${arrowButtonClasses} left-0`}
                onClick={_ => handleArrowClick('left')}
            >
                <LeftSVG className={`${arrowSVGClasses}`} />
            </button>
            <button
                className={`${arrowButtonClasses} right-0`}
                onClick={_ => handleArrowClick('right')}
            >
                <RightSVG className={`${arrowSVGClasses}`} />
            </button>
            <Img
                {...imagesData[imageIndex]}
                title={imagesData[imageIndex].title}
                style={{ maxHeight: '80%' }}
                className="w-3/4 flex justify-center"
                placeholderStyle={{ display: 'none' }}
                imgStyle={imgStyle}
            />
        </div>
    )
}
