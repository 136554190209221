import React from 'react'
import { BlockContent } from './blockContent'
import { graphql, useStaticQuery } from 'gatsby'
import { ServicesQuery } from '../../graphql-types'

export function Services() {
    const data: ServicesQuery = useStaticQuery(graphql`
        query Services {
            sanityServices {
                _rawInfo(resolveReferences: { maxDepth: 10 })
                header
                serviceCategories {
                    icon {
                        myImage {
                            asset {
                                url
                            }
                        }
                        alt
                    }
                    services
                    name
                }
            }
        }
    `)

    return (
        <section
            id="services"
            className="bg-primary opacity-80 shadow col-center space-y-10 section pb-20 lg:pb-24"
        >
            <div className="space-y-3">
                <h2>{data.sanityServices?.header}</h2>
                <BlockContent blocks={data.sanityServices?._rawInfo} />
            </div>
            <div className="flex flex-wrap justify-center items-start">
                {data.sanityServices?.serviceCategories?.map(
                    (serviceCategory, i) => {
                        const imgURL =
                            serviceCategory?.icon?.myImage?.asset?.url
                        const key = imgURL ? imgURL + i : i
                        return (
                            <div className="col-center space-y-3 m-8" key={key}>
                                {imgURL && serviceCategory?.icon?.alt && (
                                    <img
                                        src={imgURL}
                                        alt={serviceCategory?.icon?.alt}
                                        className="w-1/2"
                                    />
                                )}
                                <h3 className="">{serviceCategory?.name}</h3>
                                <ul className="space-y-2">
                                    {serviceCategory?.services?.map(
                                        (service, i) => (
                                            <li
                                                className="opacity-90"
                                                key={service ? service + i : i}
                                            >
                                                {service}
                                            </li>
                                        ),
                                    )}
                                </ul>
                            </div>
                        )
                    },
                )}
            </div>
        </section>
    )
}
