import React, { useState } from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'

import defaultTheme from 'tailwindcss/defaultTheme'
import { useMedia } from '../utils/useMedia'
import HamburgerSVG from '../images/menu.inline.svg'
import { HeaderQuery } from '../../graphql-types'

export function Header() {
    const data: HeaderQuery = useStaticQuery(graphql`
        query Header {
            sanitySettings {
                menuLogo {
                    myImage {
                        asset {
                            fluid(maxWidth: 900) {
                                ...GatsbySanityImageFluid
                            }
                        }
                    }
                    alt
                }
            }
            sanityAbout {
                header
            }
            sanityServices {
                header
            }
            sanityOurWork {
                header
            }
            sanityContact {
                header
            }
        }
    `)
    const [dropdownActive, setDropdownActive] = useState<boolean>(false)
    const smallScreen = useMedia(
        [`(max-width: ${defaultTheme.screens.md})`],
        [true],
        false,
    )

    function toggleDropdownActive() {
        setDropdownActive(prev => !prev)
    }

    const headerPad = 'p-4'
    const linksUlClasses = [
        'text-white uppercase tracking-widest text-base font-light font-body',
    ].join(' ')
    const linksLiClasses = [
        headerPad,
        'py-2 opacity-80 focus:opacity-100 hover:opacity-100',
    ].join(' ')
    const bgClasses = 'bg-primary'

    const links = (
        <HeaderLinks
            liClasses={linksLiClasses}
            onLinkClick={() => toggleDropdownActive()}
            headerQuery={data}
        />
    )

    return (
        <>
            <header
                className={
                    'w-screen h-20 z-10 sticky top-0 left-0 shadow ' + bgClasses
                }
            >
                <div
                    className={
                        'm-auto flex w-full max-w-body justify-between items-center h-full ' +
                        headerPad
                    }
                >
                    <Link to="/" className="w-1/2 h-full">
                        <Img
                            fluid={
                                data.sanitySettings?.menuLogo?.myImage?.asset
                                    ?.fluid as FluidObject
                            }
                            alt={data.sanitySettings?.menuLogo?.alt || 'logo'}
                            className="w-full h-full"
                            imgStyle={{ width: 'auto' }}
                        />
                    </Link>
                    {smallScreen ? (
                        <button onClick={toggleDropdownActive}>
                            <HamburgerSVG
                                className={
                                    'transform duration-100 ' +
                                    (dropdownActive
                                        ? 'rotate-90'
                                        : 'rotate-0') +
                                    ' h-10 text-logo'
                                }
                            />
                        </button>
                    ) : (
                        <ul className={linksUlClasses + ' flex'}>{links}</ul>
                    )}
                </div>
                {smallScreen && dropdownActive && (
                    <div className={`${bgClasses} w-screen absolute z-10`}>
                        <ul
                            className={
                                linksUlClasses +
                                ' divide-y divide-gray-100 divide-opacity-25'
                            }
                        >
                            {links}
                        </ul>
                    </div>
                )}
            </header>
        </>
    )
}

function HeaderLinks({
    liClasses,
    onLinkClick,
    headerQuery
}: {
    liClasses: string
    onLinkClick?: React.DOMAttributes<HTMLAnchorElement>['onClick'],
    headerQuery: HeaderQuery
}) {
    const links = [
        ['/#hero', 'Home'],
        ['/#services', headerQuery.sanityServices?.header || 'Services'],
        ['/#ourWork', headerQuery.sanityOurWork?.header || 'Our Work'],
        ['/#about', headerQuery.sanityAbout?.header || 'About'],
        ['/#contact', headerQuery.sanityContact?.header || 'Contact'],
    ]
    return (
        <>
            {links.map(linkPair => (
                <li className={liClasses} key={linkPair[0] + linkPair[1]}>
                    <a
                        href={linkPair[0]}
                        className="no-underline whitespace-no-wrap"
                        onClick={onLinkClick}
                    >
                        {linkPair[1]}
                    </a>
                </li>
            ))}
        </>
    )
}
