import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'

import { HeroQuery } from '../../graphql-types'
import { SocialMedia } from './socialMedia'
import { BlockContent } from './blockContent'
import DownSVG from '../images/chevron-down.inline.svg'

export function Hero() {
    const data: HeroQuery = useStaticQuery(graphql`
        query Hero {
            sanityHero {
                heroLogo {
                    myImage {
                        asset {
                            fluid(maxWidth: 900) {
                                ...GatsbySanityImageFluid
                            }
                        }
                    }
                    alt
                }
                title
                _rawDescription(resolveReferences: { maxDepth: 10 })
            }
        }
    `)

    return (
        <section
            className="-mt-20 p-10 w-screen h-screen col-center space-y-3 relative"
            id="hero"
        >
            {data.sanityHero?.heroLogo?.myImage?.asset?.fluid &&
                data.sanityHero?.heroLogo?.alt && (
                    <Img
                        fluid={
                            data.sanityHero?.heroLogo?.myImage?.asset
                                ?.fluid as FluidObject
                        }
                        alt={data.sanityHero?.heroLogo?.alt}
                        className="opacity-90 w-4/5 md:w-2/5 lg:w-1/4"
                    />
                )}
            <div className="flex flex-col justify-center items-center space-y-1">
                <h1 className="text-center font-bold">
                    {data.sanityHero?.title}
                </h1>
                <BlockContent
                    blocks={data.sanityHero?._rawDescription}
                    className="text-center"
                />
            </div>
            <SocialMedia />
            <a href="/#services" className={'flex justify-center'}>
                <DownSVG
                    className={
                        'absolute bottom-0 w-20 text-header transform hover:scale-105 duration-100'
                    }
                />
            </a>
        </section>
    )
}
