import React from 'react'
import { graphql, PageProps } from 'gatsby'
import { FluidObject } from 'gatsby-image'

import BackgroundImage from 'gatsby-background-image'
import { IndexQuery } from '../../graphql-types'

import './index.css'
import '../utils/globals.css'
import SEO from '../components/seo'
import { Hero } from '../components/hero'
import { Services } from '../components/services'
import { About } from '../components/about'
import { Contact } from '../components/contact'
import { SocialMedia } from '../components/socialMedia'
import { OurWork } from '../components/ourWork'
import { Header } from '../components/header'

export default function Index({ data }: PageProps<IndexQuery>) {
    return (
        <>
            <SEO title="Home" />
            <BackgroundImage
                fluid={
                    data.sanitySettings?.backgroundImage?.asset
                        ?.fluid as FluidObject
                }
                className="bg-image w-screen h-screen"
                // Prevents BackgroundImage from adding opacity styles with higher precedence than ours and which would
                // throw off stacking contexts, see https://www.gatsbyjs.org/packages/gatsby-background-image/?=background#additional-props
                preserveStackingContext={true}
            />
            <Header />
            <main className="relative z-0">
                <Hero />
                <Services />
                <OurWork />
                <About />
                <Contact />
            </main>
            <footer className="p-10 relative col-center space-y-3 bg-black opacity-75">
                <p className="text-center text-lg md:text-xl">
                    {data.site?.siteMetadata?.title} {new Date().getFullYear()}
                </p>
                <SocialMedia />
            </footer>
        </>
    )
}

export const query = graphql`
    query Index {
        sanitySettings {
            backgroundImage {
                asset {
                    fluid {
                        ...GatsbySanityImageFluid
                    }
                }
            }
        }
        site {
            siteMetadata {
                title
            }
        }
    }
`
