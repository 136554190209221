import React from 'react'
import { BlockContent } from './blockContent'
import Img, { FluidObject } from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'
import { AboutQuery } from '../../graphql-types'

export function About() {
    const data: AboutQuery = useStaticQuery(graphql`
        query About {
            sanityAbout {
                _rawBody(resolveReferences: { maxDepth: 10 })
                header
                picture {
                    myImage {
                        asset {
                            fluid(maxWidth: 600) {
                                ...GatsbySanityImageFluid
                            }
                        }
                    }
                    alt
                }
            }
        }
    `)
    return (
        <section className="elevated space-y-6 section mx-auto" id="about">
            <h2 className="text-center">{data.sanityAbout?.header}</h2>
            <BlockContent
                className="space-y-4"
                blocks={data.sanityAbout?._rawBody}
            />
            {data.sanityAbout?.picture?.myImage?.asset?.fluid &&
                data.sanityAbout?.picture?.alt && (
                    <Img
                        fluid={
                            data.sanityAbout.picture.myImage.asset
                                .fluid as FluidObject
                        }
                        alt={data.sanityAbout.picture.alt}
                        className="rounded-lg max-w-sm m-auto"
                    />
                )}
        </section>
    )
}
