import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { SocialMediaQuery } from '../../graphql-types'

export function SocialMedia() {
    const data: SocialMediaQuery = useStaticQuery(graphql`
        query SocialMedia {
            allSanitySocialMedia {
                nodes {
                    link
                    icon {
                        alt
                        myImage {
                            asset {
                                url
                            }
                        }
                    }
                }
            }
        }
    `)
    return (
        <ul className="flex">
            {data.allSanitySocialMedia?.nodes?.map((node, i) => {
                return (
                    node?.link && (
                        <li
                            key={node.link + i}
                            className="w-10 mx-2 opacity-75 hover:opacity-100 hover:shadow transition-all duration-100"
                        >
                            <a href={node?.link}>
                                {node?.icon?.alt &&
                                    node?.icon?.myImage?.asset?.url && (
                                        <img
                                            src={node.icon.myImage.asset.url}
                                            alt={node.icon.alt}
                                        />
                                    )}
                            </a>
                        </li>
                    )
                )
            })}
        </ul>
    )
}
